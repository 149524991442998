import React from 'react';
import ReactDOM from 'react-dom';
import './styles/App.css';
import App from './App';

import posthog from 'posthog-js'

posthog.init('phc_HndQizq31fJ08A4wCFdT8AbM0yDwG3A4gv0DJ6uhOCy',
    {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'always'
    }
)

document.documentElement.lang = 'es';

window.hideAncestorsById = function(elementId, numAncestors) {
    // Get the element from the parent document by its ID
    var element = window.parent.document.getElementById(elementId);

    // If the element is found
    if (element) {
        var ancestor = element;

        // Traverse up to the required number of ancestors
        for (var i = 0; i < numAncestors; i++) {
            if (ancestor.parentElement) {
                ancestor = ancestor.parentElement;
            } else {
                return;
            }
        }

        // Store the original display style in sessionStorage
        if (ancestor.style.display !== 'none') {
            sessionStorage.setItem(elementId + '_display', ancestor.style.display || ''); // Preserve the original value
        }

        // Hide the ancestor element
        ancestor.style.display = 'none';
    }
};

window.unhideAncestorsById = function(elementId, numAncestors) {
    // Get the element from the parent document by its ID
    var element = window.parent.document.getElementById(elementId);

    // If the element is found
    if (element) {
        var ancestor = element;

        // Traverse up to the required number of ancestors
        for (var i = 0; i < numAncestors; i++) {
            if (ancestor.parentElement) {
                ancestor = ancestor.parentElement;
            } else {
                return;
            }
        }

        // Retrieve the original display style from sessionStorage
        var originalDisplay = sessionStorage.getItem(elementId + '_display');

        // Unhide the ancestor element and apply the original display style
        ancestor.style.display = originalDisplay || ''; // Apply the saved display style or default to ''
    }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

